import React, { ReactElement } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import 'src/styles/main.scss'

import { HomePage } from './Homepage'
import { TermsAndConditions } from './TermsAndConditions'
import { PrivacyPolitics } from './PrivacyPolitics'
import { MercadoPagoCheckout } from './MercadoPagoCheckout'
import { ShareTrip } from './ShareTrip'
import { Ads } from './Ads'

function App(): ReactElement {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/terminos-y-condiciones" component={TermsAndConditions} />
          <Route exact path="/politicas-de-privacidad" component={PrivacyPolitics} />
          <Route exact path="/mercadopago/checkout" component={MercadoPagoCheckout} />
          <Route exact path="/compartir-viaje/:tripId" component={ShareTrip} />
          <Route exact path="/ads/campaign-qr" component={Ads} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </div>
  )
}

export default App
